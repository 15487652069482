.main {
  min-height: 100%;
  padding: 16px;
  flex: 1;
}

.footer {
  padding: 8px;
  margin: 0 auto;
}

.appBar.appBarActive {
  background-color: orangered;
}
