import * as styles from "../../client/grid/hex.module.css";

export function ReversteamRivers() {
  return (
    <>
      <path
        className={styles.riverPath}
        d="m 52.5,1727.5 c 35.947538,5.4117 44.151753,48.8171 43.477999,79.1093 2.929386,27.3564 -21.819663,57.3613 -0.779911,81.5592 34.834502,31.7671 91.586242,15.8162 124.785412,51.1668 35.31519,31.4366 54.03046,86.7893 104.97453,96.8134 35.94978,-2.6391 69.41597,13.6068 95.16524,37.7606 23.39186,23.9015 54.78606,34.4239 87.04301,38.2056 44.06529,17.2261 67.04724,72.7958 118.85796,73.2372 46.09139,0.3927 97.0087,26.3231 106.99671,75.316 2.90492,14.2491 6.94871,29.1616 1.97905,43.3319"
      />
      <path
        className={styles.riverPath}
        d="m 52.5,1242.5 c 43.71582,30.5 52.72217,56.3502 52.5,91.5"
      />
      <path
        className={styles.riverPath}
        d="m 1627.5,1364 c -24.5297,14.0708 -47.0863,18.11 -69.5594,35.2934 -21.6348,16.5424 -55.3153,46.9582 -75.7372,61.972 -47.5181,34.9343 -111.7534,31.2483 -117.6081,108.7801 -5.0269,66.57 26.6762,129.1976 8.7467,194.8317 -20.4761,74.9567 -22.7026,119.8473 -8.342,194.3103 11.3216,58.7049 -64.4156,104.7985 -107.5446,122.7193 -59.9015,24.8901 -135.37,60.8146 -109.8657,153.4425 6.1168,22.2155 5.383,45.7316 7.4103,68.6507"
      />
    </>
  );
}
