export function FactoryBlack({ translateY }: { translateY: number }) {
  return (
    <g transform={`translate(0, ${translateY}) scale(0.2)`}>
      <g>
        <g>
          <path
            d="M155.566,0c-0.006,0.074-1.129,7.869-6.862,12.605c-4.467,3.691-11.246,5.1-20.147,4.198
				c-16.225-1.65-30.576,2.219-41.502,11.18C69.341,42.517,66.73,64.876,66.491,67.377l31.119,2.977
				c0.011-0.115,1.581-11.89,9.273-18.202c4.472-3.669,10.525-5.058,18.512-4.247c17.526,1.785,32.067-1.987,43.218-11.197
				c15.81-13.06,17.905-31.839,18.091-33.937L155.566,0z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M263.565,0c-0.006,0.074-1.129,7.869-6.862,12.605c-4.467,3.691-11.248,5.1-20.148,4.198
				c-16.226-1.65-30.578,2.219-41.502,11.18c-17.714,14.534-20.325,36.892-20.563,39.395l31.12,2.977l-0.014,0.139
				c0.008-0.073,0.89-7.406,5.163-13.74c3.022-4.479,8.66-10.262,22.636-8.848c17.526,1.785,32.067-1.987,43.218-11.197
				c15.81-13.06,17.905-31.839,18.091-33.937L263.565,0z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M263.69,289.705v15.631v15.631v157.686h-31.856V320.967v-15.631v-15.631V143.555l-33.794,29.553V86.824h-31.262v113.628
				l-15.173,13.266v20.768v20.768v224.441h-1.042h-15.631h-14.589V241.061V190.74v-20.758l-23.04,20.143l-0.004,0.003V94.119H66.037
				v123.155h0.22l-28.237,24.694V512h81.282h31.262h50.009h31.262h242.146V289.705H263.69z M317.878,351.229h100.039v31.262H317.878
				V351.229z M418.907,410.054v68.6h-31.262v-37.337h-39.422v37.337h-31.262v-68.6L418.907,410.054L418.907,410.054z"
          />
        </g>
      </g>
    </g>
  );
}
