import * as styles from "../../client/grid/hex.module.css";

export function RustBeltRivers() {
  return (
    <>
      <path
        className={styles.riverPath}
        d="m 157.5,1424.5 c 24.51636,60.9213 107.52856,32.0255 131.50414,90.3292 24.98366,60.7551 102.16967,25.9747 131.2037,74.6725 27.74105,46.5291 27.57696,112.1342 10.57338,163.0788 -19.11565,57.2726 -13.49955,120.2641 -0.34938,171.2738 13.73567,53.2809 -35.97824,104.8253 -1.16307,147.5266 35.28644,43.2793 94.25413,57.9294 150.52227,70.6436 53.95005,12.1904 67.61584,85.4752 68.88465,134.3035 0.75722,29.1403 -12.3971,62.2026 -18.67569,87.672"
      />
      <path
        className={styles.riverPath}
        d="m 1732.5,1788.5 c -77.7235,34.3566 -42.3437,85.116 -43.963,123.4243 -2.3853,56.4306 -77.7013,38.8786 -109.7416,73.4843 -32.329,34.9174 -64.0807,57.2978 -109.8352,89.5586 -46.7827,32.9858 -61.8242,14.1934 -103.9602,46.5328 -31.7439,24.3635 -97.1413,52.0839 -124.7901,82.8667 -33.7624,37.5893 -88.8807,51.0664 -136.7772,61.5387 -34.8792,7.6262 -60.073,49.881 -59.4327,98.4446"
      />
      <path
        className={styles.riverPath}
        d="m 1732.5,1303.5 c -29.3123,12.7233 -56.6394,49.8736 -52.5,90.5"
      />
    </>
  );
}
