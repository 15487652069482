import {
  black,
  city,
  grid,
  MOUNTAIN,
  plain,
  PLAIN,
  RIVER,
  town,
  UNPASSABLE,
  WATER,
  white,
} from "../factory";
import { BLACK, BLUE, PURPLE, RED, YELLOW } from "../../engine/state/good";
import { CityData } from "../../engine/state/space";
import z from "zod";

function portCity(name: string): CityData {
  return {
    ...city(name, [], undefined, 0),
    mapSpecific: {
      portCity: true,
    },
  };
}

export const GermanyMapData = z.object({
  portCity: z.boolean().optional(),
});
export type GermanyMapData = z.infer<typeof GermanyMapData>;

export const map = grid([
  [
    WATER,
    WATER,
    WATER,
    WATER,
    UNPASSABLE,
    portCity("To Rotterdam"),
    UNPASSABLE,
    UNPASSABLE,
    portCity("To Antwerp"),
    UNPASSABLE,
    UNPASSABLE,
    portCity("To Paris"),
    UNPASSABLE,
    UNPASSABLE,
    portCity("To Lyon"),
  ],
  [
    WATER,
    WATER,
    WATER,
    PLAIN,
    plain({ terrainCost: 6 }),
    plain({ terrainCost: 6 }),
    RIVER,
    plain({ terrainCost: 7 }),
    plain({ terrainCost: 7 }),
    PLAIN,
    plain({ terrainCost: 11 }),
    plain({ terrainCost: 11 }),
    PLAIN,
    plain({ terrainCost: 10 }),
  ],
  [
    WATER,
    WATER,
    WATER,
    PLAIN,
    PLAIN,
    PLAIN,
    RIVER,
    city("Düsseldorf", [RED], white(3), 2),
    RIVER,
    PLAIN,
    PLAIN,
    town("Saarbrücken"),
    MOUNTAIN,
    MOUNTAIN,
    MOUNTAIN,
  ],
  [
    WATER,
    WATER,
    WATER,
    city("Bremen", [BLUE], white(4), 2),
    PLAIN,
    PLAIN,
    city("Essen", [BLUE], black(3), 2),
    PLAIN,
    RIVER,
    RIVER,
    PLAIN,
    PLAIN,
    town("Frieburg"),
    plain({ terrainCost: 9 }),
  ],
  [
    WATER,
    WATER,
    WATER,
    RIVER,
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    town("Frankfurt"),
    PLAIN,
    city("Stuttgart", [BLUE], white(2), 2),
    MOUNTAIN,
    plain({ terrainCost: 9 }),
    city("To Zürich", [PURPLE], white(1), 2),
  ],
  [
    PLAIN,
    PLAIN,
    town("Hamburg"),
    RIVER,
    city("Hannover", [RED], white(5), 2),
    RIVER,
    town("Kassel"),
    PLAIN,
    MOUNTAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    MOUNTAIN,
    plain({ terrainCost: 9 }),
  ],
  [
    WATER,
    plain({ terrainCost: 8 }),
    WATER,
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    town("Würzburg"),
    PLAIN,
    PLAIN,
    PLAIN,
    MOUNTAIN,
    MOUNTAIN,
  ],
  [
    portCity("To Copenhagen"),
    WATER,
    WATER,
    PLAIN,
    PLAIN,
    town("Magdeburg"),
    PLAIN,
    PLAIN,
    MOUNTAIN,
    PLAIN,
    city("Nuremberg", [RED], black(2), 2),
    PLAIN,
    city("Munich", [RED], black(1), 2),
    MOUNTAIN,
  ],
  [
    WATER,
    WATER,
    WATER,
    town("Rostock"),
    PLAIN,
    PLAIN,
    PLAIN,
    town("Leipzig"),
    PLAIN,
    MOUNTAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    MOUNTAIN,
    MOUNTAIN,
  ],
  [
    WATER,
    WATER,
    PLAIN,
    PLAIN,
    city("Berlin", [BLACK], undefined, 4),
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    MOUNTAIN,
    MOUNTAIN,
    PLAIN,
    MOUNTAIN,
    MOUNTAIN,
  ],
  [
    WATER,
    WATER,
    WATER,
    RIVER,
    PLAIN,
    PLAIN,
    PLAIN,
    PLAIN,
    city("Dresden", [BLUE], black(5), 2),
    MOUNTAIN,
    town("Pilsen"),
    MOUNTAIN,
    town("Passau"),
    MOUNTAIN,
    MOUNTAIN,
  ],
  [
    WATER,
    WATER,
    plain({ terrainCost: 12 }),
    town("Szczecin"),
    plain({ terrainCost: 8 }),
    plain({ terrainCost: 8 }),
    town("Gorlitz"),
    plain({ terrainCost: 9 }),
    plain({ terrainCost: 9 }),
    MOUNTAIN,
    MOUNTAIN,
    MOUNTAIN,
    MOUNTAIN,
    plain({ terrainCost: 10 }),
  ],
  [
    WATER,
    WATER,
    city("To Königsberg", [YELLOW], black(6), 3),
    plain({ terrainCost: 12 }),
    UNPASSABLE,
    portCity("To Warsaw"),
    UNPASSABLE,
    plain({ terrainCost: 8 }),
    city("To Breslau", [PURPLE], white(6), 2),
    plain({ terrainCost: 9 }),
    town("Prague"),
    PLAIN,
    MOUNTAIN,
    plain({ terrainCost: 10 }),
    city("To Vienna", [YELLOW], black(4), 2),
  ],
]);
