export function FactoryYellow({ translateY }: { translateY: number }) {
  return (
    <g transform={`translate(0, ${translateY}) scale(0.17)`}>
      <g>
        <path
          style={{ fill: "#FFE100" }}
          d="M75.8,84.333c-5.12,0-8.533-3.413-8.533-8.533c0-18.773,15.36-34.133,34.133-34.133h34.133
				c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H101.4c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133
				c14.507,0,25.6,11.093,25.6,25.6s-11.093,25.6-25.6,25.6H101.4c-9.387,0-17.067,7.68-17.067,17.067
				C84.333,80.92,80.92,84.333,75.8,84.333z"
        />
        <path
          style={{ fill: "#FFE100" }}
          d="M263.533,84.333c-5.12,0-8.533-3.413-8.533-8.533c0-18.773,15.36-34.133,34.133-34.133h34.133
				c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533h-34.133c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533
				h34.133c14.507,0,25.6,11.093,25.6,25.6s-11.093,25.6-25.6,25.6h-34.133c-9.387,0-17.067,7.68-17.067,17.067
				C272.067,80.92,268.653,84.333,263.533,84.333z"
        />
        <path
          style={{ fill: "#FFE100" }}
          d="M434.2,84.333c-5.12,0-8.533-3.413-8.533-8.533c0-18.773,15.36-34.133,34.133-34.133h25.6
				c5.12,0,8.533-3.413,8.533-8.533S490.52,24.6,485.4,24.6h-25.6c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h25.6
				c14.507,0,25.6,11.093,25.6,25.6s-11.093,25.6-25.6,25.6h-25.6c-9.387,0-17.067,7.68-17.067,17.067
				C442.733,80.92,439.32,84.333,434.2,84.333z"
        />
        <polygon
          style={{ fill: "#FFE100" }}
          points="365.933,314.733 365.933,203.8 246.467,314.733 246.467,203.8 127,314.733 127,203.8 
				7.533,289.133 7.533,502.467 127,502.467 246.467,502.467 365.933,502.467 485.4,502.467 485.4,203.8 		"
        />
      </g>
      <g>
        <polygon
          style={{ fill: "#FDCC00" }}
          points="24.6,135.533 109.933,135.533 109.933,101.4 24.6,101.4 		"
        />
        <polygon
          style={{ fill: "#FDCC00" }}
          points="383,135.533 468.333,135.533 468.333,101.4 383,101.4 		"
        />
        <polygon
          style={{ fill: "#FDCC00" }}
          points="203.8,135.533 306.2,135.533 306.2,101.4 203.8,101.4 		"
        />
        <polygon
          style={{ fill: "#FDCC00" }}
          points="451.267,235.373 451.267,135.533 400.067,135.533 400.067,283.16 		"
        />
      </g>
      <g>
        <polygon
          style={{ fill: "#FFA800" }}
          points="468.333,220.013 468.333,502.467 485.4,502.467 485.4,203.8 		"
        />
        <polygon
          style={{ fill: "#FFA800" }}
          points="451.267,135.533 468.333,135.533 468.333,101.4 451.267,101.4 		"
        />
        <polygon
          style={{ fill: "#FFA800" }}
          points="434.2,135.533 434.2,246.467 400.067,280.6 400.067,283.16 451.267,235.373 
				451.267,135.533 		"
        />
      </g>
      <polygon
        style={{ fill: "#FDCC00" }}
        points="92.867,228.547 92.867,135.533 41.667,135.533 41.667,264.387 	"
      />
      <g>
        <polygon
          style={{ fill: "#FFFFFF" }}
          points="127,215.747 127,203.8 7.533,289.133 7.533,502.467 24.6,502.467 24.6,289.133 		"
        />
        <polygon
          style={{ fill: "#FFFFFF" }}
          points="24.6,135.533 41.667,135.533 41.667,101.4 24.6,101.4 		"
        />
        <polygon
          style={{ fill: "#FFFFFF" }}
          points="58.733,135.533 41.667,135.533 41.667,264.387 58.733,252.44 		"
        />
      </g>
      <polygon
        style={{ fill: "#FDCC00" }}
        points="246.467,314.733 289.133,275.48 289.133,135.533 220.867,135.533 220.867,227.693 
			246.467,203.8 	"
      />
      <path
        d="M203.8,357.4h-34.133c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533H203.8
			c5.12,0,8.533,3.413,8.533,8.533C212.333,353.987,208.92,357.4,203.8,357.4z"
      />
      <path
        d="M203.8,391.533h-34.133c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533H203.8
			c5.12,0,8.533,3.413,8.533,8.533C212.333,388.12,208.92,391.533,203.8,391.533z"
      />
      <path
        d="M203.8,425.667h-34.133c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533H203.8c5.12,0,8.533,3.413,8.533,8.533
			S208.92,425.667,203.8,425.667z"
      />
      <path
        d="M203.8,459.8h-34.133c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533H203.8c5.12,0,8.533,3.413,8.533,8.533
			S208.92,459.8,203.8,459.8z"
      />
      <path
        d="M485.4,511H7.533C2.413,511-1,507.587-1,502.467V289.133c0-2.56,1.707-5.12,3.413-6.827l119.467-85.333
			c2.56-1.707,5.973-1.707,8.533-0.853c2.56,1.707,4.267,4.267,4.267,7.68v91.307l104.96-97.28c2.56-2.56,5.973-2.56,9.387-1.707
			c3.413,1.707,5.12,4.267,5.12,7.68v91.307l104.96-97.28c2.56-2.56,5.973-2.56,9.387-1.707c3.413,1.707,5.12,4.267,5.12,7.68v91.307
			l104.96-97.28c2.56-2.56,5.973-2.56,9.387-1.707c3.413,1.707,5.12,4.267,5.12,7.68v298.667C493.933,507.587,490.52,511,485.4,511z
			M16.067,493.933h460.8V223.427l-104.96,97.28c-2.56,2.56-5.973,2.56-9.387,1.707c-3.413-0.853-5.12-4.267-5.12-7.68v-91.307
			l-104.96,97.28c-2.56,2.56-5.973,2.56-9.387,1.707c-3.413-0.853-5.12-4.267-5.12-7.68v-91.307l-104.96,97.28
			c-2.56,2.56-5.973,2.56-9.387,1.707c-3.413-0.853-5.12-4.267-5.12-7.68v-94.72L16.067,293.4V493.933z"
      />
      <path
        d="M84.333,357.4H50.2c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
			c5.12,0,8.533,3.413,8.533,8.533C92.867,353.987,89.453,357.4,84.333,357.4z"
      />
      <path
        d="M84.333,391.533H50.2c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
			c5.12,0,8.533,3.413,8.533,8.533C92.867,388.12,89.453,391.533,84.333,391.533z"
      />
      <path
        d="M84.333,425.667H50.2c-5.12,0-8.533-3.413-8.533-8.533S45.08,408.6,50.2,408.6h34.133c5.12,0,8.533,3.413,8.533,8.533
			S89.453,425.667,84.333,425.667z"
      />
      <path
        d="M84.333,459.8H50.2c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
			S89.453,459.8,84.333,459.8z"
      />
      <path
        d="M323.267,357.4h-34.133c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
			c5.12,0,8.533,3.413,8.533,8.533C331.8,353.987,328.387,357.4,323.267,357.4z"
      />
      <path
        d="M323.267,391.533h-34.133c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
			c5.12,0,8.533,3.413,8.533,8.533C331.8,388.12,328.387,391.533,323.267,391.533z"
      />
      <path
        d="M323.267,425.667h-34.133c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
			S328.387,425.667,323.267,425.667z"
      />
      <path
        d="M323.267,459.8h-34.133c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
			S328.387,459.8,323.267,459.8z"
      />
      <path
        d="M442.733,357.4H408.6c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
			c5.12,0,8.533,3.413,8.533,8.533C451.267,353.987,447.853,357.4,442.733,357.4z"
      />
      <path
        d="M442.733,391.533H408.6c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
			c5.12,0,8.533,3.413,8.533,8.533C451.267,388.12,447.853,391.533,442.733,391.533z"
      />
      <path
        d="M442.733,425.667H408.6c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
			S447.853,425.667,442.733,425.667z"
      />
      <path
        d="M442.733,459.8H408.6c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
			S447.853,459.8,442.733,459.8z"
      />
      <path
        d="M109.933,144.067H24.6c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533h85.333
			c5.12,0,8.533,3.413,8.533,8.533v34.133C118.467,140.653,115.053,144.067,109.933,144.067z M33.133,127H101.4v-17.067H33.133V127z"
      />
      <path
        d="M468.333,144.067H383c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533h85.333
			c5.12,0,8.533,3.413,8.533,8.533v34.133C476.867,140.653,473.453,144.067,468.333,144.067z M391.533,127H459.8v-17.067h-68.267V127
			z"
      />
      <path
        d="M67.267,75.8c-5.12,0-8.533-3.413-8.533-8.533c0-18.773,15.36-34.133,34.133-34.133H127c5.12,0,8.533-3.413,8.533-8.533
			s-3.413-8.533-8.533-8.533H92.867c-5.12,0-8.533-3.413-8.533-8.533S87.747-1,92.867-1H127c14.507,0,25.6,11.093,25.6,25.6
			S141.507,50.2,127,50.2H92.867C83.48,50.2,75.8,57.88,75.8,67.267C75.8,72.387,72.387,75.8,67.267,75.8z"
      />
      <path
        d="M306.2,144.067H203.8c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533h102.4
			c5.12,0,8.533,3.413,8.533,8.533v34.133C314.733,140.653,311.32,144.067,306.2,144.067z M212.333,127h85.333v-17.067h-85.333V127z"
      />
      <path
        d="M255,75.8c-5.12,0-8.533-3.413-8.533-8.533c0-18.773,15.36-34.133,34.133-34.133h34.133c5.12,0,8.533-3.413,8.533-8.533
			s-3.413-8.533-8.533-8.533H280.6c-5.12,0-8.533-3.413-8.533-8.533S275.48-1,280.6-1h34.133c14.507,0,25.6,11.093,25.6,25.6
			s-11.093,25.6-25.6,25.6H280.6c-9.387,0-17.067,7.68-17.067,17.067C263.533,72.387,260.12,75.8,255,75.8z"
      />
      <path
        d="M425.667,75.8c-5.12,0-8.533-3.413-8.533-8.533c0-18.773,15.36-34.133,34.133-34.133h25.6c5.12,0,8.533-3.413,8.533-8.533
			s-3.413-8.533-8.533-8.533h-25.6c-5.12,0-8.533-3.413-8.533-8.533S446.147-1,451.267-1h25.6c14.507,0,25.6,11.093,25.6,25.6
			s-11.093,25.6-25.6,25.6h-25.6c-9.387,0-17.067,7.68-17.067,17.067C434.2,72.387,430.787,75.8,425.667,75.8z"
      />
      <path
        d="M400.067,291.693c-0.853,0-2.56,0-3.413-0.853c-3.413-1.707-5.12-4.267-5.12-7.68V135.533c0-5.12,3.413-8.533,8.533-8.533
			h51.2c5.12,0,8.533,3.413,8.533,8.533v99.84c0,2.56-0.853,4.267-2.56,5.973l-51.2,47.787
			C404.333,290.84,401.773,291.693,400.067,291.693z M408.6,144.067v119.467l34.133-31.573v-87.893H408.6z"
      />
      <path
        d="M41.667,272.92c-1.707,0-2.56,0-4.267-0.853c-2.56-0.853-4.267-4.267-4.267-7.68V135.533c0-5.12,3.413-8.533,8.533-8.533
			h51.2c5.12,0,8.533,3.413,8.533,8.533v93.013c0,2.56-1.707,5.12-3.413,6.827l-51.2,36.693
			C45.08,272.92,43.373,272.92,41.667,272.92z M50.2,144.067v104.107l34.133-24.747v-79.36H50.2z"
      />
      <path
        d="M246.467,323.267c-0.853,0-2.56,0-3.413-0.853c-3.413-0.853-5.12-4.267-5.12-7.68v-91.307l-11.093,10.24
			c-2.56,2.56-5.973,2.56-9.387,1.707c-3.413-1.707-5.12-4.267-5.12-7.68v-92.16c0-5.12,3.413-8.533,8.533-8.533h68.267
			c5.12,0,8.533,3.413,8.533,8.533V275.48c0,2.56-0.853,4.267-2.56,5.973l-42.667,39.253
			C250.733,322.413,248.173,323.267,246.467,323.267z M246.467,195.267c0.853,0,2.56,0,3.413,0.853c3.413,1.707,5.12,4.267,5.12,7.68
			v91.307l25.6-23.893V144.067h-51.2v64l11.093-10.24C242.2,196.12,244.76,195.267,246.467,195.267z"
      />
    </g>
  );
}
