export function FactoryRed({ translateY }: { translateY: number }) {
  return (
    <g transform={`translate(0, ${translateY}) scale(0.171)`}>
      <polygon
        style={{ fill: "#D4D4D4" }}
        points="105.086,80.156 52.544,80.156 31.644,154.956 105.086,134.057 "
      />
      <polygon
        style={{ fill: "#EDEDED" }}
        points="0,80.156 0,134.057 52.544,154.956 52.544,80.156 "
      />
      <polygon
        style={{ fill: "#D61322" }}
        points="512,166.398 376.362,220.57 376.362,166.398 256,214.468 235.101,320.784 256,431.844 
		512,431.844 "
      />
      <polygon
        style={{ fill: "#EC2533" }}
        points="240.725,220.57 240.725,166.398 105.086,220.57 105.086,134.057 0,134.057 0,431.844 
		256,431.844 256,214.468 "
      />
      <polygon
        style={{ fill: "#FF9500" }}
        points="178.875,289.886 141.981,289.886 121.082,326.779 141.981,363.673 178.875,363.673 "
      />
      <rect
        x="105.085"
        y="289.886"
        style={{ fill: "#FFC222" }}
        width="36.895"
        height="73.791"
      />
      <polygon
        style={{ fill: "#FF9500" }}
        points="314.512,289.886 277.619,289.886 256.719,326.779 277.619,363.673 314.512,363.673 "
      />
      <rect
        x="240.724"
        y="289.886"
        style={{ fill: "#FFC222" }}
        width="36.895"
        height="73.791"
      />
      <polygon
        style={{ fill: "#FF9500" }}
        points="450.15,289.886 413.256,289.886 392.357,326.779 413.256,363.673 450.15,363.673 "
      />
      <rect
        x="376.362"
        y="289.886"
        style={{ fill: "#FFC222" }}
        width="36.895"
        height="73.791"
      />
    </g>
  );
}
