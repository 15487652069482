.gameListCard {
  display: flex;
  flex-direction: column;
}

.gameList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(320px, 100%), 1fr));
  gap: 16px;
}
