import * as styles from "../../client/grid/hex.module.css";

export function IndiaRivers() {
  return (
    <>
      <path
        className={styles.riverPath}
        d="m 374.05506,1423.178 c 15.76738,34.5631 52.84422,41.344 87.77413,41.6472 45.89381,0.3984 45.9274,61.6286 79.48555,77.6599 26.26224,12.5459 57.94276,8.5224 81.88357,28.9036 21.50301,18.3058 45.39546,33.9113 70.00511,47.677 26.79467,14.9879 58.74121,2.6105 82.48127,-16.7881 25.83115,-21.1073 81.39352,-19.3369 78.6672,-63.309 -1.7175,-27.701 33.87785,-60.899 5.11604,-79.6677 -26.09292,-17.0271 -58.83185,-21.7365 -89.69362,-26.3563 -40.47071,-6.0582 -55.15239,-39.0891 -89.8064,-57.5519 -24.12518,-12.8533 -51.01396,-55.915 -77.91592,-28.0735 -42.06596,40.0691 -96.06486,38.9722 -128.95878,17.6935"
      />
      <path
        className={styles.riverPath}
        d="m 636.19171,1342.6112 c -16.18593,-8.9858 -42.3983,-34.033 -68.18839,-33.0502 -19.79806,0.7544 -44.75844,-5.2157 -54.35966,-25.4762 -8.39731,-17.72 -22.37111,-29.9447 -37.40641,-41.6907"
      />
      <path
        className={styles.riverPath}
        d="m 719.90251,1405.8856 c 7.83863,-24.9343 30.49066,-47.4364 28.73738,-75.9495 -1.37446,-22.3525 -18.78106,-34.2544 -22.70038,-57.1962 -4.25662,-24.9162 -1.68123,-42.3108 8.1113,-61.7865"
      />
      <path
        className={styles.riverPath}
        d="m 892.5,1364 c -20.0903,-11.2077 -59.24647,-36.4363 -52.5,-91"
      />
      <path
        className={styles.riverPath}
        d="m 851.17411,1454.8392 c 26.38382,6.0645 72.98673,56.5124 102.87257,55.141 29.24662,-1.3421 47.41022,-22.4974 72.93182,-32.9323 30.8318,-12.6061 65.0207,-5.4683 88.9025,12.5954 22.8697,17.2982 36.7831,47.2453 41.4994,75.4522 4.2833,25.6171 15.9404,68.5851 -13.9985,81.5377 -13.6671,5.9128 -31.1122,2.0989 -43.0474,12.3498"
      />
      <path
        className={styles.riverPath}
        d="m 1136.5869,1512.0374 c 20.9804,17.9033 51.591,6.6578 59.043,-21.149 7.1285,-26.5997 45.3565,-26.6305 59.3475,-51.6132 12.3492,-22.051 17.8938,-46.3227 10.7829,-71.4113 -7.2316,-25.5143 -28.5119,-47.0269 -20.6524,-75.1365 1.5856,-5.6707 11.258,-15.0922 14.8921,-19.7274"
      />
      <path
        className={styles.riverPath}
        d="m 1102.5,1364 c -15.6601,-18.6429 -43.2819,-4.4453 -62.6661,-15.1979 -17.468,-9.6896 -31.377,-33.1728 -7.74,-43.586 14.8461,-6.5404 17.6702,-17.228 17.9061,-32.2161"
      />
      <path
        className={styles.riverPath}
        d="m 1266.3192,1415.4132 c -19.5676,36.5332 13.015,73.344 58.4651,69.8115 48.9738,-3.8063 74.2904,49.6173 115.1755,72.2958 40.303,22.3556 97.2078,14.479 118.5216,60.7029 20.4795,44.4145 51.8801,54.1707 89.0921,79.1266 17.1495,11.5011 32.1616,39.0793 32.4265,60.65"
      />
      <path
        className={styles.riverPath}
        d="m 1445.4029,1561.2145 c 13.7684,12.5555 26.0968,17.1983 27.3818,31.0821 1.6256,17.5646 -21.3099,43.4676 -17.987,58.6158 7.1871,32.7643 35.8149,61.6682 29.0875,96.1201 -5.0718,25.9733 -23.0625,51.8922 -8.9152,78.7557 7.9642,15.1228 26.5352,20.8651 47.53,23.2118"
      />
      <path
        className={styles.riverPath}
        d="m 1578.8842,1649.2974 c -15.6515,-25.9576 -19.0571,-59.2772 -3.6352,-75.3648 15.1911,-15.8468 11.0915,-34.4046 6.4374,-51.4377 -4.0316,-14.7547 9.1511,-24.7864 23.1372,-22.7601 23.5348,3.4096 55.1104,1.0578 67.4299,-21.9002 16.8054,-31.3178 22.6019,-45.7372 60.2465,-53.3346"
      />
      <path
        className={styles.riverPath}
        d="m 1375.615,1502.3027 c 26.1008,38.6277 -27.3095,70.1417 -18.1223,95.3365 11.6683,31.9991 7.7088,56.9073 -23.0891,74.7507 -30.91,17.9084 -62.8901,3.1288 -92.4266,18.5772 -15.2244,7.9628 -19.515,25.9784 -34.744,32.4693"
      />
      <path
        className={styles.riverPath}
        d="m 1417.5,2031 c -21.1461,-28.2991 -66.5103,-11.0795 -84.972,-44.151 -18.1695,-32.5481 -60.6593,-28.7217 -88.4977,-47.8844 -23.0621,-15.8749 -27.031,-41.0723 -43.7275,-51.1396 -35.1232,-21.1779 -58.9113,-29.4606 -97.8028,21.675"
      />
      <path
        className={styles.riverPath}
        d="m 0,2001 c -0.97803796,-28.258 0.48285596,-50.6981 19.800287,-70.1604 27.16038,-27.3642 55.958769,-42.888 87.803583,-51.8965 33.91833,-9.5951 63.62407,-31.3723 92.56731,-51.1925 32.34768,-22.1516 64.96583,30.911 95.26334,40.6027 39.68959,12.6961 77.01549,-18.6431 104.05967,-43.9055 21.13838,-19.7457 53.44997,-15.5751 73.00581,-35.9478"
      />
      <path
        className={styles.riverPath}
        d="m 1022.8645,2175.4803 c -19.9035,-6.209 -35.57536,-5.976 -47.57175,-28.8588 -21.99723,-41.9592 -61.7445,-59.5134 -110.70416,-60.6222 -44.09167,-0.9985 -73.37706,-46.7142 -109.3333,-71.4476 -41.41231,-28.4865 -65.55836,29.3993 -107.19068,34.4054 -49.53577,5.9565 -81.54222,-23.524 -107.82381,-57.4972 -16.92264,-21.8752 -46.62721,-6.3292 -67.7408,-20.9599"
      />
      <path
        className={styles.riverPath}
        d="m 1076.2896,2192.0203 c 14.0924,5.6665 19.8689,16.6814 26.2104,20.4797"
      />
    </>
  );
}
