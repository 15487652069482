import * as styles from "../../client/grid/hex.module.css";

export function IrelandRivers() {
  return (
    <>
      <path
        className={styles.riverPath}
        d="m 367.5,1727.5 c 21.42033,-14.8662 42.84675,-24.8817 65.78219,-30.1914 28.25566,-6.5413 38.58228,-45.2771 68.75281,-42.1435 30.41557,3.1591 48.39367,-18.9136 67.2893,-37.2935 19.97901,-19.4337 30.91555,-31.5286 57.94491,-35.3233 17.45243,-2.4502 37.32644,8.7667 55.23079,23.9517"
      />
      <path
        className={styles.riverPath}
        d="m 787.5,1667 c 25.26392,9.5607 40.06887,20.0556 55.3647,36.5456 17.98278,19.3867 54.05247,13.7928 69.75637,34.3359 21.59971,28.2558 56.28109,33.0548 85.37193,48.1501"
      />
    </>
  );
}
