import { SpaceType } from "../../engine/state/location_type";
import { LandData } from "../../engine/state/space";
import {
  BOTTOM,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  TOP_LEFT,
  TOP_RIGHT,
} from "../../engine/state/tile";
import { duplicate } from "../../utils/functions";
import {
  black,
  city,
  grid,
  MOUNTAIN,
  plain,
  PLAIN,
  town,
  UNPASSABLE,
  white,
} from "../factory";

const HILL: LandData = {
  type: SpaceType.HILL,
};

export const map = grid([
  [PLAIN, PLAIN, PLAIN],
  [
    PLAIN,
    HILL,
    PLAIN,
    ...duplicate(3, UNPASSABLE),
    town("Taean"),
    ...duplicate(7, UNPASSABLE),
    plain({ unpassableEdges: [TOP_RIGHT, BOTTOM_RIGHT] }),
  ],
  [
    city("Pyongyang", [], [], 4),
    HILL,
    HILL,
    PLAIN,
    city("Inchon", [], white(3), 3),
    UNPASSABLE,
    PLAIN,
    { ...HILL, unpassableEdges: [TOP_LEFT] },
    ...duplicate(3, UNPASSABLE),
    ...duplicate(4, PLAIN),
    town("Wando"),
  ],
  [
    HILL,
    HILL,
    PLAIN,
    PLAIN,
    city("Suwon", [], [], 0),
    plain({ unpassableEdges: [BOTTOM_LEFT, BOTTOM] }),
    HILL,
    HILL,
    town("Kunsan"),
    ...duplicate(3, PLAIN),
    city("Kangju", [], black(5), 2),
    HILL,
    PLAIN,
  ],
  [
    MOUNTAIN,
    HILL,
    town("Tonch on"),
    HILL,
    city("Seoul", [], white(4), 2),
    PLAIN,
    town("Chonan"),
    PLAIN,
    PLAIN,
    PLAIN,
    city("Chonju", [], black(3), 2),
    HILL,
    HILL,
    HILL,
  ],
  [
    MOUNTAIN,
    ...duplicate(3, HILL),
    PLAIN,
    HILL,
    PLAIN,
    city("Taejon", [], white(6), 2),
    ...duplicate(3, HILL),
    MOUNTAIN,
    HILL,
    PLAIN,
    town("Kahung"),
  ],
  [
    MOUNTAIN,
    MOUNTAIN,
    city("Chum", [], white(1), 2),
    ...duplicate(3, HILL),
    city("Chongju", [], white(5), 2),
    ...duplicate(3, HILL),
    town("Anui"),
    HILL,
    HILL,
    plain({ unpassableEdges: [BOTTOM_LEFT, TOP_RIGHT] }),
  ],
  [
    MOUNTAIN,
    HILL,
    HILL,
    HILL,
    town("Wanju"),
    HILL,
    HILL,
    HILL,
    town("Kimchon"),
    HILL,
    HILL,
    town("Chinju"),
    plain({ unpassableEdges: [BOTTOM_RIGHT] }),
  ],
  [HILL, town("Kansong"), ...duplicate(10, HILL), PLAIN, town("Kosong")],
  [
    UNPASSABLE,
    PLAIN,
    PLAIN,
    HILL,
    HILL,
    HILL,
    town("Yongyu"),
    HILL,
    HILL,
    city("Taegu", [], black(2), 2),
    PLAIN,
    city("Changwon", [], black(4), 2),
  ],
  [
    ...duplicate(3, UNPASSABLE),
    city("Kangnung", [], white(2), 2),
    PLAIN,
    HILL,
    HILL,
    HILL,
    MOUNTAIN,
    HILL,
    HILL,
    HILL,
    PLAIN,
  ],
  [
    ...duplicate(4, UNPASSABLE),
    PLAIN,
    town("Ulchin"),
    ...duplicate(4, HILL),
    MOUNTAIN,
    PLAIN,
    city("Pusan", [], black(6), 3),
  ],
  [
    ...duplicate(6, UNPASSABLE),
    PLAIN,
    town("Yongdok"),
    PLAIN,
    city("Pohang", [], black(1), 2),
    HILL,
    town("Ulsan"),
  ],
]);
