import { Good } from "../../engine/state/good";
import { duplicate } from "../../utils/functions";
import { black, city, grid, PLAIN, town, UNPASSABLE, white } from "../factory";

export const map = grid([
  [
    UNPASSABLE,
    UNPASSABLE,
    UNPASSABLE,
    PLAIN,
    PLAIN,
    city("Gothenburg", Good.BLUE, [white(1)]),
    PLAIN,
    PLAIN,
  ],
  [UNPASSABLE, PLAIN, town("Åmål"), ...duplicate(6, PLAIN)],
  [
    UNPASSABLE,
    PLAIN,
    PLAIN,
    UNPASSABLE,
    UNPASSABLE,
    PLAIN,
    town("Borås"),
    PLAIN,
    PLAIN,
    city("Halmstad", Good.BLUE, [white(2)]),
    PLAIN,
    PLAIN,
    PLAIN,
    city("Malmö", Good.BLUE, [white(3)]),
  ],
  [
    PLAIN,
    city("Karlstad", Good.RED, [white(4)]),
    UNPASSABLE,
    UNPASSABLE,
    ...duplicate(7, PLAIN),
    town("Hässelholm"),
    PLAIN,
    PLAIN,
  ],
  [
    PLAIN,
    PLAIN,
    UNPASSABLE,
    UNPASSABLE,
    PLAIN,
    PLAIN,
    city("Jönköping", Good.YELLOW, [white(5)]),
    ...duplicate(7, PLAIN),
  ],
  [
    PLAIN,
    town("Karlskoga"),
    PLAIN,
    town("Mariestad"),
    UNPASSABLE,
    UNPASSABLE,
    PLAIN,
    PLAIN,
    city("Växjö", Good.YELLOW, [white(6)]),
    PLAIN,
    town("Tingsryd"),
    PLAIN,
  ],
  [...duplicate(4, PLAIN), UNPASSABLE, ...duplicate(7, PLAIN)],
  [
    PLAIN,
    city("Örebro", Good.RED, [black(1)]),
    PLAIN,
    PLAIN,
    city("Linköping", Good.YELLOW, [black(2)]),
    PLAIN,
    town("Vimmerby"),
    ...duplicate(4, PLAIN),
    city("Karlskrona", Good.RED, [black(3)]),
  ],
  [...duplicate(9, PLAIN), town("Oskarshamn"), PLAIN, PLAIN],
  [
    PLAIN,
    PLAIN,
    town("Katrineholm"),
    PLAIN,
    town("Norrköping"),
    PLAIN,
    PLAIN,
    city("Västervik", Good.RED, [black(4)]),
  ],
  [city("Västerås", Good.BLUE, [black(5)]), ...duplicate(4, PLAIN)],
  [...duplicate(4, PLAIN)],
  [UNPASSABLE, PLAIN, PLAIN, city("Västerås", Good.BLUE, [black(6)])],
]);
