.header {
  font-size: 1rem;
}

.currentPlayer {
  font-weight: bold;
  text-decoration: underline;
}

.turnOrder {
  margin: 8px 0;
}
