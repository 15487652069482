.black {
  --good-color: #444444;
}

.blue {
  --good-color: #00a2d3;
}

.purple {
  --good-color: #8d5a95;
}

.red {
  --good-color: #d41e2d;
}

.yellow {
  --good-color: #f5ac11;
}
