.hexGrid * {
  pointer-events: none;
}

.hexGrid g {
  pointer-events: inherit;
}

.hexGrid .clickable {
  pointer-events: inherit;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.hexGridContainer {
  position: relative;
  overflow-x: auto;
  margin-left: -16px;
  width: calc(100% + 32px);
}

.fabs {
  display: flex;
  gap: 4px;
}

.floatingFabs {
  position: absolute;
}
