.city {
  fill: var(--good-color);
}

.colorless {
  --good-color: rgb(224, 99, 59);
}

.location.plain {
  fill: #99c37b;
}

:global(.dark-mode) .location.plain {
  fill: #4a6c32;
}

.location.river {
  fill: #8eb572;
}

:global(.dark-mode) .location.river {
  fill: #466133;
}

.location.water {
  fill: #579ba8;
}

:global(.dark-mode) .location.water {
  fill: #2b4d54;
}

.location.hill {
  fill: #537845;
}

:global(.dark-mode) .location.hill {
  fill: #2a3e23;
}

.location.mountain {
  fill: #867565;
}

:global(.dark-mode) .location.mountain {
  fill: #433a32;
}

.location.desert {
  fill: #ffffcc;
}

:global(.dark-mode) .location.desert {
  fill: #666600;
}

.location.swamp {
  fill: green;
}

.location.unpassable {
  fill: lightgrey;
}

.riverPath {
  stroke: #009bb2;
  stroke-width: 5;
  fill: none;
}
