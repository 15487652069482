.goodsContainer {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 4px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
}

.row > *,
.column > * {
  flex: 1;
  text-align: center;
}

.goodPlace {
  aspect-ratio: 1 / 1;
  background-color: var(--good-color);
}

.empty {
  --good-color: lightgrey;
}

.good {
  border: solid 1px grey;
}

.clickableGood {
  cursor: pointer;
}

.gapRight {
  margin-right: 8px;
}
