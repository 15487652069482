.form {
  display: flex;
  flex-direction: column;
}

div.tab {
  margin-left: 42px;
}

div.tab2 {
  margin-left: 60px;
}
