export function FactoryBlue({ translateY }: { translateY: number }) {
  return (
    <g transform={`translate(0, ${translateY}) scale(0.163)`} fill="blue">
      <path
        d="M480.701,95.413c-0.741-5.679-5.608-9.936-11.323-9.936h-57.236c-5.739,0-10.605,4.256-11.371,9.936l-34.423,258.56
		V231.574c0-4.472-2.618-8.525-6.66-10.402c-4.089-1.865-8.872-1.196-12.255,1.71l-95.592,82.01v-73.329
		c0-4.472-2.606-8.525-6.66-10.402c-4.065-1.865-8.848-1.196-12.243,1.71l-95.569,82.01v-73.329c0-4.472-2.606-8.525-6.672-10.402
		c-4.041-1.865-8.836-1.196-12.232,1.71L3.982,321.093c-2.511,2.212-3.982,5.381-3.982,8.728v196.78
		c0,6.325,5.129,11.454,11.454,11.454h515.146c3.264,0,6.433-1.423,8.621-3.91c2.176-2.475,3.181-5.763,2.726-9.039L480.701,95.413z
		 M114.485,411.041c0,3.169-2.559,5.727-5.727,5.727h-80.12c-3.169,0-5.727-2.571-5.727-5.727V376.69
		c0-3.169,2.571-5.727,5.727-5.727h80.132c3.169,0,5.727,2.571,5.727,5.727L114.485,411.041L114.485,411.041z M228.945,411.041
		c0,3.169-2.559,5.727-5.727,5.727h-80.132c-3.169,0-5.727-2.571-5.727-5.727V376.69c0-3.169,2.559-5.727,5.727-5.727h80.132
		c3.169,0,5.727,2.571,5.727,5.727V411.041z M343.429,411.041c0,3.169-2.571,5.727-5.727,5.727h-80.133
		c-3.169,0-5.727-2.571-5.727-5.727V376.69c0-3.169,2.571-5.727,5.727-5.727h80.132c3.157,0,5.727,2.571,5.727,5.727
		L343.429,411.041L343.429,411.041z"
      />
      <path
        d="M183.163,22.885c5.56,0,9.23,5.476,15.759,17.062c6.815,12.124,16.141,28.732,35.714,28.732
		c19.561,0,28.899-16.608,35.714-28.732c6.516-11.586,10.199-17.062,15.759-17.062c5.56,0,9.243,5.476,15.759,17.062
		c6.815,12.124,16.189,28.732,35.726,28.732c19.597,0,28.935-16.608,35.75-28.732c6.54-11.586,10.211-17.062,15.795-17.062
		c5.62,0,9.29,5.476,15.807,17.062c6.803,12.124,16.189,28.732,35.738,28.732c6.325,0,11.466-5.118,11.466-11.442
		s-5.141-11.454-11.466-11.454c-5.584,0-9.254-5.464-15.795-17.062C418.097,16.596,408.747,0,389.162,0
		c-19.573,0-28.935,16.608-35.738,28.732c-6.516,11.61-10.211,17.062-15.807,17.062c-5.56,0-9.242-5.464-15.759-17.062
		C315.031,16.596,305.669,0,286.12,0c-19.585,0-28.899,16.608-35.726,28.732c-6.516,11.598-10.199,17.05-15.759,17.05
		c-5.56,0-9.243-5.452-15.759-17.05C212.062,16.596,202.736,0,183.163,0c-6.325,0-11.454,5.129-11.454,11.454
		C171.709,17.767,176.85,22.885,183.163,22.885z"
      />
    </g>
  );
}
