import * as styles from "../../client/grid/hex.module.css";

export function GermanyRivers() {
  return (
    <>
      <path
        className={styles.riverPath}
        d="m 367.5,1182 c 17.06553,10.2403 45.04158,10.3076 54.62262,27.4817 8.03582,14.4043 25.18841,18.5565 37.83806,27.7063 10.7999,7.8118 22.75653,13.6696 34.19304,20.4069 14.40787,8.4877 18.76074,25.3424 27.95891,38.1052 C 530.77653,1307.7215 525,1325 525,1334"
      />
      <path
        className={styles.riverPath}
        d="m 525.00001,1455 c -10e-6,9.5759 10.62952,11.7464 12.3272,21.652 2.99608,17.4815 -22.64838,25.9351 -16.2357,42.6604 5.41099,14.1128 8.2946,31.4375 4.52148,45.9218"
      />
      <path
        className={styles.riverPath}
        d="m 52.500009,1606.5 c 7.499991,4.7441 13.023096,17.3561 27.861898,16.7934 44.498933,-1.6874 42.860573,-11.4507 73.361733,-32.08 18.60695,-13.5436 55.77866,-16.2987 75.56689,-5.2704 10.88028,7.2013 21.90611,12.345 33.20947,20.557"
      />
      <path
        className={styles.riverPath}
        d="m 208.23053,1767.8584 c -3.36499,14.9208 -4.66532,46.6632 5.36581,60.5137 14.42443,19.9166 38.43026,38.8647 61.82233,32.5457 23.4266,-6.3284 47.89227,5.7465 48.07498,56.2232 -0.92434,21.4491 -20.90301,37.251 -23.62171,58.2314 -2.22887,17.2004 2.23134,26.5666 15.12806,45.5227"
      />
      <path
        className={styles.riverPath}
        d="m 1102.5,1242.5 c -32.4133,-21.7583 -53.7534,-52.9745 -52.5,-90.5"
      />
    </>
  );
}
